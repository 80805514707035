import { React, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Container,
  Typography,
  Drawer,
  List,
  ListItem,
  Stack,
  Box,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { ROUTING_NAMES } from "../../constants/routingConstant";
import { Close } from "@mui/icons-material";

const LandingPageHeader = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const { pathname } = useLocation();
  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };
  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const Navbar = () => {
    return (
      <AppBar position="sticky">
        <Container>
          <Toolbar>
            <Hidden mdUp>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, width: "min-content" }}
            >
              <Link to="/">
                <img
                  src="/landingPage/Ekalsutra_Logo.svg"
                  alt=""
                  style={{ width: "16rem" }}
                />
              </Link>
            </Typography>
            <Hidden smDown>
              <Link
                className="mx-2 text-capitalize"
                to="/"
                style={{
                  borderBottom: `2px solid ${
                    pathname === ROUTING_NAMES.home ? "#2789FD" : "#fff"
                  }`,
                }}
              >
                Home
              </Link>

              <Link
                className="mx-2 text-capitalize"
                to={ROUTING_NAMES.aboutUs}
                style={{
                  borderBottom: `2px solid ${
                    pathname === ROUTING_NAMES.aboutUs ? "#2789FD" : "#fff"
                  }`,
                }}
              >
                About Us
              </Link>

              <Link
                className="mx-2 text-capitalize"
                to={ROUTING_NAMES.blogs}
                style={{
                  borderBottom: `2px solid ${
                    pathname === ROUTING_NAMES.blogs ? "#2789FD" : "#fff"
                  }`,
                }}
              >
                Blogs
              </Link>

              <a
                className="mx-3 text-capitalize px-4 py-2 signup"
                href={process.env.REACT_APP_SCHOOL_REGISTER}
                target="_blank"
                rel="noreferrer noopener"
              >
                Sign Up
              </a>
              <a
                className="mx-3 text-capitalize px-4 py-2 login"
                href={process.env.REACT_APP_SCHOOL_LOGIN}
                target="_blank"
                rel="noreferrer noopener"
              >
                Login
              </a>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
    );
  };

  return (
    <Wrapper>
      <Box
        sx={{
          backgroundColor: "rgba(238, 246, 255, 1)",
          display: { xs: "none", md: "block" },
        }}
      >
        <Container>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} gap={0.5}>
              <a
                href="https://twitter.com/EkalSutra"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="twitter"
                    src="/landingPage/twitter-active.png"
                    className="active"
                  />
                  <img
                    alt="twitter"
                    src="/landingPage/twitter-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
              <a
                href="https://www.linkedin.com/company/ekalsutraofficial/"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="linkedin"
                    src="/landingPage/linkedin-active.png"
                    className="active"
                  />
                  <img
                    alt="linkedin"
                    src="/landingPage/linkedin-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
              <a
                href="https://www.facebook.com/ekalsutrahelp"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="facebook"
                    src="/landingPage/facebook-active.png"
                    className="active"
                  />
                  <img
                    alt="facebook"
                    src="/landingPage/facebook-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
              <a
                href="https://www.instagram.com/ekalsutra/"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="insta"
                    src="/landingPage/insta-active.png"
                    className="active"
                  />
                  <img
                    alt="insta"
                    src="/landingPage/insta-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
              <a
                href="https://www.youtube.com/@ekalsutra"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="youtube"
                    src="/landingPage/youtube-active.png"
                    className="active"
                  />
                  <img
                    alt="youtube"
                    src="/landingPage/youtube-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
            </Stack>
            <Typography
              sx={{
                fontSize: 14,
                fontWight: 500,
                margin: "1rem 0",
              }}
            >
              Contact US :{" "}
              <a style={{ fontWeight: 700 }} href="tel: +918318667477">
                +91 831 866 7477
              </a>
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWight: 500,
                margin: "1rem 0",
              }}
            >
              Mail Us :{" "}
              <a
                style={{ fontWeight: 700 }}
                href="mailto: contactus@ekalsutra.com"
              >
                contactus@ekalsutra.com
              </a>
            </Typography>
            {/* <a
              href="https://anydesk.com/en"
              rel="noreferrer noopener"
              target="_blank"
              style={{
                textDecoration: "none",
                fontStyle: "italic",
                color: "#2789FD",
              }}
            >
              Click Helpdesk
            </a> */}
          </Stack>
        </Container>
      </Box>

      <Box
        sx={{
          position: "fixed",
          top: offset > 130 ? "0" : "-200px",
          width: "100%",
          zIndex: 1200,
          transition: "all 0.5s",
        }}
      >
        <Navbar />
      </Box>
      <Navbar />

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        className="landing-page-menu"
      >
        <NavbarDrawerWrapper>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={1}
            className="drawer_top"
          >
            <Link to="/">
              <img
                src="/landingPage/Ekalsutra_Logo.svg"
                alt=""
                style={{ width: "14rem" }}
              />
            </Link>
            <CloseIconBox onClick={() => toggleDrawer(false)}>
              <Close />
            </CloseIconBox>
          </Stack>

          <List>
            <ListItem>
              <Link
                onClick={() => toggleDrawer(false)}
                className="link_text"
                to="/"
              >
                Home
              </Link>
            </ListItem>

            <ListItem>
              <Link
                onClick={() => toggleDrawer(false)}
                className="link_text"
                to={ROUTING_NAMES.aboutUs}
              >
                About Us
              </Link>
            </ListItem>
            <ListItem>
              <Link
                className="mx-2 text-capitalize"
                to={ROUTING_NAMES.blogs}
                style={{
                  borderBottom: `2px solid ${
                    pathname === ROUTING_NAMES.blogs ? "#2789FD" : "#fff"
                  }`,
                }}
              >
                Blogs
              </Link>
            </ListItem>

            <Stack direction={"row"} gap={1} justifyContent={"center"} pt={2}>
              <Button
                variant="outlined"
                href={process.env.REACT_APP_SCHOOL_REGISTER}
                target="_blank"
                rel="noreferrer noopener"
                color="secondary"
                size="large"
                sx={{ borderRadius: "10px" }}
              >
                Sign Up
              </Button>
              <Button
                variant="contained"
                href={process.env.REACT_APP_SCHOOL_LOGIN}
                target="_blank"
                rel="noreferrer noopener"
                color="secondary"
                size="large"
                sx={{ borderRadius: "10px" }}
              >
                Login
              </Button>
            </Stack>

            <hr className="my-3" />
            <Stack direction={"row"} gap={0.5} justifyContent={"center"}>
              <a
                href="https://twitter.com/EkalSutra"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="twitter"
                    src="/landingPage/twitter-active.png"
                    className="active"
                  />
                  <img
                    alt="twitter"
                    src="/landingPage/twitter-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
              <a
                href="https://www.linkedin.com/company/ekalsutraofficial/"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="linkedin"
                    src="/landingPage/linkedin-active.png"
                    className="active"
                  />
                  <img
                    alt="linkedin"
                    src="/landingPage/linkedin-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
              <a
                href="https://www.facebook.com/ekalsutrahelp"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="facebook"
                    src="/landingPage/facebook-active.png"
                    className="active"
                  />
                  <img
                    alt="facebook"
                    src="/landingPage/facebook-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
              <a
                href="https://www.instagram.com/ekalsutra/"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="insta"
                    src="/landingPage/insta-active.png"
                    className="active"
                  />
                  <img
                    alt="insta"
                    src="/landingPage/insta-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
              <a
                href="https://www.youtube.com/@ekalsutra"
                rel="noreferrer noopener"
                target="_blank"
              >
                <MediaBox>
                  <img
                    alt="youtube"
                    src="/landingPage/youtube-active.png"
                    className="active"
                  />
                  <img
                    alt="youtube"
                    src="/landingPage/youtube-inactive.png"
                    className="inactive"
                  />
                </MediaBox>
              </a>
            </Stack>

            <div className="mx-3 mt-4">
              <Typography className="link_text">
                Contact US :{" "}
                <a style={{ fontWeight: 700 }} href="tel: +918318667477">
                  +91 831 866 7477
                </a>
              </Typography>

              <Typography className="link_text" my={1}>
                Mail Us :{" "}
                <a
                  style={{ fontWeight: 700 }}
                  href="mailto: contactus@ekalsutra.com"
                >
                  contactus@ekalsutra.com
                </a>
              </Typography>
              {/* <a
                href="https://anydesk.com/en"
                rel="noreferrer noopener"
                target="_blank"
                style={{
                  textDecoration: "none",
                  fontStyle: "italic",
                  color: "#2789FD",
                }}
              >
                Click Helpdesk
              </a> */}
            </div>
          </List>
        </NavbarDrawerWrapper>
      </Drawer>
    </Wrapper>
  );
};

export default LandingPageHeader;
const MediaBox = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid rgba(8, 76, 132, 0.2);
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  .inactive {
    display: none;
  }
  .active {
    display: block;
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
  &:hover {
    background-color: rgba(39, 137, 253, 1);
    border: 0.5px solid rgba(39, 137, 253, 1);
    .inactive {
      display: block;
    }
    .active {
      display: none;
    }
  }
`;
const NavbarDrawerWrapper = styled.div`
  .drawer_top {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .link_text {
    text-decoration: none;
    color: #31436d;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
`;
const CloseIconBox = styled.div`
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #31436d30;
`;
const Wrapper = styled.div`
  header {
    background-color: #ffffff;
    color: #31436d;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1.75rem 2rem -0.6rem,
      rgba(0, 0, 0, 0.04) 0px 0.8rem 0.8rem -0.6rem;
  }
  a {
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 500;
    color: #31436d;
  }
  .login {
    background: #2789fd;
    color: #fff;
    border-radius: 0.8rem;
    border: 1px;
  }
  .signup {
    color: #2789fd;
    border: 1px solid #2789fd;
    background: transparent;
    border-radius: 0.8rem;
  }
`;
