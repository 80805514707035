import React, { useState } from "react";
import styled from "styled-components";
import {
  Box,
  Typography,
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";

// Icon
import HeadsetIcon from "@mui/icons-material/Headset";
import MailIcon from "@mui/icons-material/Mail";
import PinDropIcon from "@mui/icons-material/PinDrop";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import BookIcon from "@mui/icons-material/Book";
import SchoolIcon from "@mui/icons-material/School";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import { useMutation } from "react-query";
import { contactUs } from "../../../services/school.service";

const GetInTouch = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    school: "",
    address: "",
    subject: "",
    message: "",
    showSuccessAlert: null,
    showErrorAlert: null,
  });
  const handleSubmitForm = (e) => {
    e.preventDefault();
    contactUsMutate({
      visitorName: state.name,
      visitorMail: state.email,
      visitorMobileNumber: state.mobileNumber,
      subject: state.subject,
      visitorMessage: state.message,
      address: state.address,
    });
  };
  const onChange = (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const { mutate: contactUsMutate, isLoading: contactUsLoading } = useMutation(
    "contactUs",
    contactUs,
    {
      onSuccess: (success) => {
        setState((prev) => ({
          ...prev,
          showSuccessAlert: success.data.message,
          name: "",
          email: "",
          mobileNumber: "",
          school: "",
          address: "",
          subject: "",
          message: "",
        }));
      },
      onError: (error) => {
        setState((prev) => ({
          ...prev,
          showErrorAlert: error?.response?.data?.message,
        }));
      },
    }
  );
  const handleCloseSuccess = () => {
    setState((prev) => ({ ...prev, showSuccessAlert: "" }));
  };
  const handleCloseError = () => {
    setState((prev) => ({ ...prev, showErrorAlert: "" }));
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={state.showSuccessAlert}
        onClose={handleCloseSuccess}
        autoHideDuration={5000}
      >
        <Alert severity="success" onClose={handleCloseSuccess}>
          {state.showSuccessAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={state.showErrorAlert}
        onClose={handleCloseError}
        autoHideDuration={5000}
      >
        <Alert severity="error" onClose={handleCloseError}>
          {state.showErrorAlert}
        </Alert>
      </Snackbar>
      <Wrapper>
        <Box className="row justify-content-between content">
          <Box className="getStartedCard col-md-4">
            <Typography
              sx={{
                fontSize: {
                  xs: 14,
                  lg: 20,
                },
                fontWeight: 700,
              }}
            >
              Ready To
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: 26,
                  lg: 40,
                },
                fontWeight: 700,
              }}
            >
              Get Started <br className="d-lg-block d-none" /> With Us
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              href="https://calendly.com/ekalsutra"
              target="_blank"
              rel="noreferrer noopener"
              size="large"
              sx={{
                border: "1px solid #fff",
                boxShadow: "none",
                borderRadius: "10px",
                fontSize: "14px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.secondary.main,
                },
              }}
            >
              Get Started
            </Button>

            <hr className="my-3" />
            <Box className="details">
              <HeadsetIcon />
              <Typography className="ms-3">
                <span className="heading">Call us today :</span>
                <br />
                <a
                  className="value"
                  href="tel: +918318667477"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  +91 831 866 7477
                </a>
              </Typography>
            </Box>
            <hr />
            <Box className="details">
              <MailIcon />
              <Typography className="ms-3">
                <span className="heading">Mail Us :</span>
                <br />
                <a
                  className="value"
                  style={{ color: "#fff", textDecoration: "none" }}
                  href="mailto: contactus@ekalsutra.com"
                >
                  contactus@ekalsutra.com
                </a>
              </Typography>
            </Box>
            <hr />
            <Box className="details">
              <PinDropIcon />
              <Typography className="ms-3">
                <span className="heading">HQ Address :</span>
                <br />
                <span className="value">
                  4th Floor, CAS, Sector 11, Jankipuram Vistar, Lucknow, Uttar
                  Pradesh 226021
                </span>
              </Typography>
            </Box>
          </Box>

          <Box className="col-md-7 getInTouchForm">
            <Typography
              variant="h1"
              color="initial"
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: "#31436D",
              }}
            >
              Let's Get In Touch
            </Typography>
            <form onSubmit={handleSubmitForm}>
              <Box className="d-flex flex-wrap mt-3 justify-content-between">
                <Box className="custom-input w-48 col-12">
                  <PermIdentityIcon
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  />
                  <TextField
                    required
                    value={state.name}
                    onChange={onChange}
                    name="name"
                    placeholder="Enter Your Name"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
                <Box className="custom-input w-48 col-12">
                  <MailIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  <TextField
                    type="email"
                    required
                    value={state.email}
                    onChange={onChange}
                    name="email"
                    placeholder="Email Id"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
                <Box className="custom-input w-48 col-12">
                  <PhoneForwardedIcon
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  />
                  <TextField
                    type="number"
                    required
                    value={state.mobileNumber}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        setState((prev) => ({
                          ...prev,
                          mobileNumber: e.target.value,
                        }));
                      }
                    }}
                    name="mobileNumber"
                    placeholder="Mobile Number"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
                <Box className="custom-input w-48 col-12">
                  <SchoolIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  <TextField
                    required
                    value={state.school}
                    onChange={onChange}
                    name="school"
                    placeholder="School/Organization Name"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
                <Box className="custom-input col-12">
                  <PinDropIcon
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  />
                  <TextField
                    required
                    value={state.address}
                    onChange={onChange}
                    name="address"
                    placeholder="Address"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
                <Box className="custom-input col-12">
                  <BookIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  <TextField
                    required
                    value={state.subject}
                    onChange={onChange}
                    name="subject"
                    placeholder="Enter Subject"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
                <Box className="custom-input col-12">
                  <TextField
                    required
                    value={state.message}
                    onChange={onChange}
                    name="message"
                    placeholder="Your Message"
                    multiline
                    rows={4}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
              </Box>
              <Box className="text-end mt-3">
                {contactUsLoading ? (
                  <CircularProgress />
                ) : (
                  <Button type="submit">Submit Now</Button>
                )}
              </Box>
            </form>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};

export default GetInTouch;
const Wrapper = styled.div`
  padding-bottom: 4rem;
  .content::before {
    content: "";
    height: 50%;
    width: 100%;
    background-color: #1a4e94;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  svg {
    width: 2.25rem;
    height: 2.1rem;
  }
  button {
    padding: 1rem 1.5rem;
  }
  .getStartedCard {
    padding: 2.25rem;
    background-color: #2789fd;
    border-radius: 1.25rem;
    p,
    button,
    svg,
    hr {
      color: #ffffff;
    }
    button {
      margin-top: 1rem;
      border: 1px solid #ffffff;
      border-radius: 1rem;
      text-transform: capitalize;
      font-size: 1.55rem;
      font-weight: 500;
      line-height: 1.9rem;
    }

    .details {
      display: flex;
      align-items: center;
      .heading {
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0.09em;
      }
      .value {
        font-size: 1.25rem;
        font-weight: 800;
        letter-spacing: 0.09em;
      }
    }
  }

  .getInTouchForm {
    background-color: #eef6ff;
    padding: 2.25rem 2.5rem;
    border-radius: 16.16px;
    @media (min-width: 968px) {
      .w-48 {
        width: 48%;
      }
    }
    .custom-input {
      display: flex;
      align-items: end;
      padding: 0.5rem 0.75rem;
      margin-top: 1rem;
      background-color: #ffffff;
      border-radius: 0.8rem;
      border: 1px solid #31436d1a;
      svg,
      input,
      textarea {
        color: #31436d;
      }
      input,
      textarea {
        font-size: 1.25rem;

        font-weight: 500;
      }
    }
    button {
      background-color: #2789fd;
      color: #fff;
      border-radius: 1rem;
      text-transform: capitalize;
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  @media (max-width: 762px) {
    padding: 0;
    .getStartedCard,
    .getInTouchForm {
      border-radius: 0;
    }
    .getInTouchForm {
      button {
        width: 100%;
      }
    }
  }
`;
