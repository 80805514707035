import React, { useEffect } from "react";
import { Container, Typography, styled } from "@mui/material";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <ul>
        <Typography variant="h4" mt={3}>
          Privacy Policy
        </Typography>
        <Text mt={1}>
          Company takes your privacy seriously. As electronic commerce over the
          Internet increases and the volume of data transmission over networks
          steadily rises, maintaining the trust of users necessitates vigilant
          and responsible privacy protections. Please read the following to
          learn more about how we collect, use and safeguard the information
          provided by you via this site. This policy does not apply to the
          practices of other entities or websites that Company does not own or
          control, or to people and entities that Company does not employ or
          manage.
          <br /> This Privacy Statement is incorporated into and subject to the
          ekalsutra.com terms of Use.
          <span style={{ fontWeight: "bold" }}>
            If you do not agree to the terms of this Privacy Statement, please
            do not provide us with any information and do not use this site.
          </span>
        </Text>
        <Title mt={2}>Acceptance of These Terms</Title>
        <Text mt={0.5}>
          This Privacy Policy is incorporated into and subject to EKALSUTRA
          Terms of Use. If users do not agree to the terms of this Privacy
          Policy Statement, please do not provide Company with any information
          and do not use this site. By using EKALSUTRA and voluntarily providing
          personally identifiable information and on EKALSUTRA, users consent to
          the collection and use of such personally identifiable and information
          as set forth in this Privacy Policy.
        </Text>
        <Title mt={2}>MODIFICATION OF TERMS OF USE</Title>
        <Text mt={0.5}>
          Company reserves the right to modify any provision hereof from time to
          time, and such modification shall be effective immediately upon its
          posting on Company EKALSUTRA. You agree to be bound to any changes to
          this “terms of use” if you continue to use this Company EKALSUTRA
          after any such modification is posted. Users of Company EKALSUTRA have
          an affirmative duty, to keep themselves informed of changes.
        </Text>
        <Title mt={2}>INFORMATION COLLECTED on EKALSUTRA</Title>
        <Text mt={0.5}>
          If registration is requested, Users agree to provide accurate and
          complete registration information. At the time of registration Users
          may be required to provide on EKALSUTRA certain information which
          includes your name, address, phone number, and email address
          collectively referred as “User profile content “ User upon
          registration on EKALSUTRA, Users are assigned one or more login IDs
          and passwords that will enable Users to access EKALSUTRA. Users shall
          take reasonable precautions to protect against theft, loss or
          fraudulent use of such IDs and passwords, and Users are solely
          responsible for any losses arising from another party's use of such
          IDs and passwords, either with or without User’s knowledge. The
          Children’s Online Privacy and Protection Act (“COPPA”) requires that
          online service providers obtain parental consent or provide notice
          before they knowingly collect personally identifiable information
          online from children who are under 13. Company do not knowingly
          collect or solicit personally identifiable information from a child
          under 13, except in the following circumstances:
        </Text>

        <li>
          <Text mt={1}>
            Company may collect a child’s name (first name and last initial),
            date of birth, email address or telephone number, and the child’s
            parent’s email address in order to provide notice to parents that
            Company may contact their child for the purpose of providing the
            Services through EKALSUTRA for which their teacher has signed up
            for. Company may collect personal information through the Services
            from a child under 13 where that student’s school, district, and/or
            teacher has agreed to obtain parental consent for that child to use
            the Services and disclose personal information to Company, for the
            use and benefit of providing services through EKALSUTRA.
          </Text>
        </li>
        <li>
          <Text mt={1}>
            If you are a teacher, you represent and warrant that you have
            permission and authorization from your school and/or district to use
            the Services, and for purposes of COPPA compliance, you represent
            and warrant that you are entering into these Terms on behalf of your
            school and/or district.
          </Text>
        </li>
        <li>
          <Text mt={1}>
            You may be required to provide valid school credentials and a valid
            school email address to verify your identity in order to access and
            use certain features of the Services. If Company comes to know if
            any learn personal information have been collected from a student
            under 13 other than pursuant to the above, or if Company learns that
            a student under 13 has provided personal information beyond what
            Company requests from he or she, Company will delete that
            information as quickly as possible. If you believe that a student
            under 13 may have provided Company personal information in violation
            of this paragraph, please contact Company at{" "}
            <a href="mailto: info@ekalsutra.com">info@ekalsutra.com</a> <br />
            Company receive and store any information you knowingly enter on the
            Services of EKALSUTRA, whether via computer, mobile phone, other
            wireless device, or that you provide to Company in any other way.
            This information may include Personal Information such as your name,
            phone number, email address, photograph, and, in certain
            circumstances, your school affiliation, which is used to provide
            notifications to users via the Services of EKALSUTRA from a teacher,
            school, and/or district ("alerts"), and any other information
            necessary for Company to provide EKALSUTRA services.
          </Text>
        </li>
        <Title mt={2}>USE OF INFORMATION</Title>
        <Text>
          User profile content shall be retained by Company only as custodian of
          such information and data. That such information and data shall only
          be used by specific Users and not by Company In order to facilitate
          interaction among Users of EKALSUTRA, other Users may share
          information with Users, or Users may be allowed to access certain
          information about other Users of EKALSUTRA. By entering into this
          Agreement, Users agree to treat information about other Users of
          EKALSUTRA strictly in accordance with the Agreement.
        </Text>
        <Text mt={1.5}>
          Company may send push notifications to users who are students when
          their teacher has posted something new within the class to which they
          belong. If you no longer wish to receive such communications, you may
          turn them off at the device level. If you are a parent of a child who
          is under 13, you always have the choice to no longer allow your child
          to receive such communications by contacting us at{" "}
          <a href="mailto: info@ekalsutra.com">info@ekalsutra.com</a>.
        </Text>
        <Text mt={1.5}>
          Any user may email Company at{" "}
          <a href="mailto: info@ekalsutra.com">info@ekalsutra.com</a>, if such
          user no longer wishes to receive communications from the Services.
        </Text>
        <Title mt={2}>DATA INTEGRITY</Title>
        <Text>
          We do not share, sell or rent users personally identifiable
          information to third parties. Company will not share or distribute any
          user information with third parties except as provided below
        </Text>
        <li>
          <Text mt={1}>
            When required to do so by law or regulation, or in response to a
            request from a law enforcement or governmental agency or authority
            or to establish or exercise our legal rights or defend against legal
            claims.
          </Text>
        </li>
        <li>
          <Text mt={1}>
            In order to investigate, prevent, or take action regarding illegal
            activities, suspected fraud, situations involving potential threats
            to the physical safety of any person, violations of Company terms of
            use, or as otherwise required by law.
          </Text>
        </li>
        <li>
          <Text mt={1}>
            If Company is acquired by or merged with another company. In this
            event, Company will notify user about such merger and users being
            subjected to any different privacy policy Not withstanding the
            above, Company employ other companies and people to perform tasks on
            our behalf and may need to share your information with them (and for
            them to use that information) in order to provide aspects of our
            Services to you. Some examples of services for which we may use
            agents include sending email or SMS or calls, analyzing data, and
            providing user services. These agents may use Personal Information
            we share with them, for example, to assist us, to provide their
            services to you and/or us, and to measure and improve the
            performance of their services. Company may, work with third party
            websites to enhance your online experience, to send messages or
            letting you add "apps" to your account. We have no control over the
            policies and practices of third party websites or services as to
            privacy or anything else, so if you choose to allow the automatic
            transmissions discussed above, please review all third party
            websites' or services' policies before disclosing any Personal
            Information or other content on the Website or on any third party
            website or service
          </Text>
        </li>
        <Title mt={2}>DATA PROTECTION</Title>
        <Text>
          Company has adopted reasonable industry standard practices to ensure
          that there are adequate safeguards and techniques to protect the data
          of the user against any unauthorized or unlawful disclosure. Company
          will not store, copy, disclose or use the data of the user except as
          necessary for the performance by Company of its obligations under this
          Agreement.
        </Text>
        <Text mt={1}>
          Company employs physical, electronic and procedural safeguards to
          protect the information we receive from users from unauthorized
          disclosure. Company limits access to personal information about user
          to employees of Company who we believe reasonably need to come into
          contact with that information to provide services to users or in order
          to do their jobs. Users should not share their password with anyone.
          In addition, if user suspect unauthorized access to its information,
          it is user’s responsibility to contact Company immediately. Company
          will never ask user to divulge its password in written or electronic
          communications. Please be aware that Internet data transmission is not
          always secure and Company cannot warrant that information user
          transmit utilizing this site is secure, however, Company will always
          make reasonable efforts to ensure the security of the data provided on
          EKALSUTRA. However, we cannot guarantee the security of user account
          information. Unauthorized entry or use, hardware or software failure,
          and other factors may compromise the security of user information at
          any time.
        </Text>
        <Title mt={2}>DELETION OF INFORMATION OR DATA</Title>
        <Text>
          Once User provides relevant information, data on this website for
          creating its profile, the same can be unsubscribed by providing
          request to following email id{" "}
          <a href="mailto: info@ekalsutra.com">info@ekalsutra.com</a>.
        </Text>
        <Text mt={1}>
          Please note that certain information may remain in Company records,
          server logs and archives after deletion of your account. Company
          retains this information for purposes such as diagnosing problems with
          the service and for auditing legal investigations, but reserves the
          right to delete this information in accordance with its standard
          business practices in effect from time to time. Further, information
          and other content you have provided may remain visible elsewhere to
          the extent such content was copied or stored by other users.
        </Text>
        <Text mt={1}>
          For under age users with an account that is inactive for twelve months
          or more (meaning the account has not received a message through the
          Services in that time), Company policy is to disable access to
          Personal Information associated with that account.
        </Text>
        <Text mt={1}>
          If yours or your child’s personal information changes, or if you no
          longer desire our Service, wish to refuse further contact by Company
          with your child, you may review, correct, update, delete inaccuracies,
          request deletion of your child’s information, or amend it by
          contacting us at{" "}
          <a href="mailto: info@ekalsutra.com">info@ekalsutra.com</a> or by
          logging into your account. We will respond to your request as quickly
          as possible.
        </Text>
        <Text mt={1}>
          We will retain yours or your child’s information for as long as your
          account is active or as needed to provide Services. If you wish to
          cancel yours or your child’s account or request that we no longer use
          the information to provide Services, contact us at{" "}
          <a href="mailto: info@ekalsutra.com">info@ekalsutra.com</a>.com. Note
          we will retain and use information, for example, to comply with our
          audit and legal obligations, to resolve disputes, and to enforce our
          agreements.
        </Text>
        <Title mt={2}>CONTACT</Title>
        <Text>
          To the best of Company's knowledge, all material published by Company
          on EKALSUTRA is done with the full agreement of the copyright owners
          (be that Company or another party). If you come across a situation
          where you suspect that this may not be the case, in accordance with
          the Digital Millennium Copyright Act (DMCA), or if you have any
          questions or concerns regarding privacy we ask that you contact:
        </Text>
        <Text mt={2}>DETAILS FOR CONTACTING EKALSUTRA</Text>
        <Text>
          Phone: <a href="tel: +918318667477">+91 831 866 7477</a>
        </Text>
      </ul>
    </Container>
  );
};

export default PrivacyPolicy;
const Text = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
`;
const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
`;
