import React from "react";
import styled from "styled-components";
import { Container, Box, Typography, Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTING_NAMES } from "../../constants/routingConstant";
// import mapImg from "../../../public/landingPage/map.png";
const LandingPageFooter = () => {
  return (
    <Wrapper>
      <div className="back_image">
        <Container className="d-flex flex-wrap main ">
          <Box className="my-lg-0 my-4 col-lg col-12">
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, width: "min-content" }}
            >
              <img
                src="/landingPage/Ekalsutra_Logo.svg"
                alt=""
                className="logo"
              />
            </Typography>
            <Typography className="text1 mt-4 pe-lg-5">
              Ekalsutra is one of the leading ERP solutions for educational
              institutions across the world. It is a fully customizable
              solution.
            </Typography>

            <Button
              to={ROUTING_NAMES.aboutUs}
              LinkComponent={Link}
              className="readMoreBtn mt-md-5 mt-3"
              variant="contained"
              sx={{
                "&:hover": {
                  backgroundColor: " #2789fd !important",
                },
              }}
            >
              Read More
            </Button>

            <Typography variant="h6" component="div">
              Registerd Address
            </Typography>
            <Typography className="text1">
              Goshaisingpur, Sultanpur, Uttar Pradesh, 228131
            </Typography>
          </Box>
          <Box className="my-lg-0 my-4 col-lg-3 col-6">
            <Typography className="heading">Who We Serve</Typography>
            {/* <Typography className="text1 my-2 mt-3">Features</Typography> */}
            <div>
              <Link className="text1 my-2" to={"/"}>
                School Software
              </Link>
            </div>
            <div>
              <Link className="text1 my-2" to={"/"}>
                College Software
              </Link>
            </div>
            {/* <Typography className="text1 my-2">Integration</Typography> */}
            <div>
              <a className="text1 my-2" href="tel: +918318667477">
                24/7 Services
              </a>
            </div>
          </Box>
          <Box className="my-lg-0 my-4 col-lg-3 col-6">
            <Typography className="heading">Solutions</Typography>
            <div>
              <Link className="text1 my-2 mt-3" to={"/"}>
                Teacher Software
              </Link>
            </div>
            <div>
              <Link className="text1 my-2" to={"/"}>
                School Software
              </Link>
            </div>
            <div>
              <Link className="text1 my-2" to={"/"}>
                Custom School Software
              </Link>
            </div>
            <div>
              <a
                className="text1 my-2"
                href="https://play.google.com/store/apps/details?id=com.ekalsutraparentapp&pcampaignid=web_share"
                target="_blank"
                rel="noreferrer noopener"
              >
                Parents App
              </a>
            </div>
            <div>
              <a
                className="text1 my-2"
                href="https://play.google.com/store/apps/details?id=com.ekalsutrastaffapp&pcampaignid=web_share"
                target="_blank"
                rel="noreferrer noopener"
              >
                Staff App
              </a>
            </div>
          </Box>
          <Box className="my-lg-0 my-4 col-lg-2 col-6">
            <Typography className="heading">Resources</Typography>
            {/* <Typography className="text1 my-2 mt-3">Brochures</Typography>
          <Typography className="text1 my-2">Blog</Typography> */}
            <div>
              <Link className="text1 my-2" to={ROUTING_NAMES.aboutUs}>
                About Company
              </Link>
            </div>

            <div>
              <a className="text1 my-2" href="#contact-us">
                Contact Us
              </a>
            </div>
          </Box>
        </Container>
      </div>
      <hr className="mb-2 mt-0" />
      <Container>
        <Typography
          className="text-center"
          sx={{
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          ©2024 Ekalsutra Edtech Private Limited. All rights reserved.
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          pt={1}
          pb={0.3}
        >
          <Stack direction={"row"} gap={1.5}>
            {/* <Typography
              className="linkText"
              component={Link}
              to={ROUTING_NAMES.govermentCertified}
            >
              Gov Certified
            </Typography> */}

            <Typography
              className="linkText"
              to={ROUTING_NAMES.termAndConditions}
              component={Link}
            >
              Terms and Conditions
            </Typography>

            <Typography
              className="linkText"
              to={ROUTING_NAMES.privacyPolicy}
              component={Link}
            >
              Privacy Policy
            </Typography>

            <Typography
              className="linkText"
              to={ROUTING_NAMES.refundPolicy}
              component={Link}
            >
              Refund Policy
            </Typography>

            {/* <Typography
              className="linkText"
              to={ROUTING_NAMES.security}
              component={Link}
            >
              Security
            </Typography> */}
          </Stack>
          <Stack direction={"row"} gap={1.8}>
            <a
              href="https://twitter.com/EkalSutra"
              rel="noreferrer noopener"
              target="_blank"
            >
              <MediaBox>
                <img
                  alt="twitter"
                  src="/landingPage/twitter-active.png"
                  className="active"
                />
                <img
                  alt="twitter"
                  src="/landingPage/twitter-inactive.png"
                  className="inactive"
                />
              </MediaBox>
            </a>
            <a
              href="https://www.linkedin.com/company/ekalsutraofficial/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <MediaBox>
                <img
                  alt="linkedin"
                  src="/landingPage/linkedin-active.png"
                  className="active"
                />
                <img
                  alt="linkedin"
                  src="/landingPage/linkedin-inactive.png"
                  className="inactive"
                />
              </MediaBox>
            </a>
            <a
              href="https://www.facebook.com/ekalsutrahelp"
              rel="noreferrer noopener"
              target="_blank"
            >
              <MediaBox>
                <img
                  alt="facebook"
                  src="/landingPage/facebook-active.png"
                  className="active"
                />
                <img
                  alt="facebook"
                  src="/landingPage/facebook-inactive.png"
                  className="inactive"
                />
              </MediaBox>
            </a>
            <a
              href="https://www.instagram.com/ekalsutra/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <MediaBox>
                <img
                  alt="insta"
                  src="/landingPage/insta-active.png"
                  className="active"
                />
                <img
                  alt="insta"
                  src="/landingPage/insta-inactive.png"
                  className="inactive"
                />
              </MediaBox>
            </a>
            <a
              href="https://www.youtube.com/@ekalsutra"
              rel="noreferrer noopener"
              target="_blank"
            >
              <MediaBox>
                <img
                  alt="youtube"
                  src="/landingPage/youtube-active.png"
                  className="active"
                />
                <img
                  alt="youtube"
                  src="/landingPage/youtube-inactive.png"
                  className="inactive"
                />
              </MediaBox>
            </a>
          </Stack>
        </Stack>
      </Container>
    </Wrapper>
  );
};

export default LandingPageFooter;
const MediaBox = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid rgba(8, 76, 132, 0.2);
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  .inactive {
    display: none;
  }
  .active {
    display: block;
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
  &:hover {
    background-color: rgba(39, 137, 253, 1);
    border: 0.5px solid rgba(39, 137, 253, 1);
    .inactive {
      display: block;
    }
    .active {
      display: none;
    }
  }
`;
const SocialMediaIcon = styled.img`
  height: 40px;
  width: 40px;
  object-fit: cover;
`;
const Wrapper = styled.div`
  padding-bottom: 3rem;
  color: #31436d;
  .back_image {
    padding: 3rem 0;
    background-image: url("landingPage/map.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .logo {
    width: 20rem;
  }
  .text1 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-decoration: none;
    color: #31436d;
  }
  .linkText {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-decoration: none;
    color: #31436d;
  }
  .heading {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 2.25rem;
  }
  .readMoreBtn {
    padding: 1.5rem;
    text-transform: capitalize;
    background-color: #2789fd;
    color: #f5f6fe;
    border-radius: 0.8rem;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0.1em;
    :hover {
      background-color: #2789fd !important;
    }
  }
  .social_media__wrapper {
    background-color: rgba(39, 137, 253, 1);
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    .text1 {
      font-size: 1.2rem;
      line-height: 2.5rem;
      text-decoration: none;
      color: #31436d;
    }
    .heading {
      font-size: 1.5rem;
      line-height: 1.2rem;
    }
    .readMoreBtn {
      font-size: 1.1rem;
      line-height: 1.25rem;
    }
  }
`;
